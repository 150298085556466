<template>
  <div>
    <div class="ProductCenter">
      <h2 class="wow animate__animated animate__fadeInUp">{{ ProductCenterTitle }}</h2>
      <p class="wow animate__animated animate__fadeInUp">{{ rencaizhaopText }}</p>
      <div class="p-cantent">
        <div class="recruit">
          <div v-for="(item, index) in limitedItems" v-bind:key="index" :class="['recruitLi', 'wow', 'animate__animated', 'animate__fadeInUp' , !item.recruiStatus?'isEnd':'' ]">
            <div class="recruitState" v-if="item.recruiStatus">招聘中</div>
            <div class="recruitState Expired" v-else>已截止</div>
            <div class="recruiIcon"><i class="iconfont icon-jiedigongren"></i></div>
            <h4>{{item.recruitName}}</h4>
            <h1>{{item.salary}}</h1>
            <h4>福利待遇</h4>
            <p>{{ item.welfare }}</p>
            <div class="recruitMore iconfont"><router-link to="/WuhanView">详细了解</router-link></div>
          </div>
        </div>
        <div class="toProduct iconfont wow animate__animated animate__fadeInUp" data-wow-delay="2s"><router-link to="/WuhanView">查看更多信息</router-link></div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Image } from 'element-ui'
import { WOW } from 'wowjs'
export default {
  name: 'RecruitmentTection',
  props: {
    RecruitData: Array,
    rencaizhaopText: String
  },
  data: function () {
    return {
      ProductCenterTitle: '人才招聘'
    }
  },
  computed: {
    limitedItems: function () {
      return this.RecruitData.slice(0, 3) // 只返回前三个元素
    }
  },
  created () {
    // this.getRecruitData()
  },
  mounted () {
    new WOW({ live: false }).init()
  },
  methods: {
    // getRecruitData () {
    //   const query = new AV.Query('Hongchuang')
    //   query.get('655ddb499a790104bdf2125a').then((RecruitData) => {
    //     this.RecruitData = RecruitData.toJSON().RecruitData
    //   })
    // }
  }
}
</script>
<style scoped>
.ProductCenter {
    width: 1280px;
    height: auto;
    margin: 50px auto;
}
.isEnd i,.isEnd h1,.isEnd h1::after {
    color: #bebebe !important;
  }
.isEnd .recruitMore{
    background: #bebebe !important;
  }
.Expired {
    color: #000 !important;
    background: rgb(233, 233, 233) !important;
}
.recruitState {
  position: absolute;
  right: 30px;
  top: 35px;
  background: rgba(0, 194, 80, .15);
  padding: 5px 15px;
  color: #00C250;
  border-radius: 22px;
}
.ProductCenter h2 {
    position: relative;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    line-height: 60px;
    margin-bottom: 10px;
}
.ProductCenter h2::before{
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: '';
  width: 50px;
  height: 4px;
  background: #DA1800;
}
.ProductCenter p {
    font-size: 14px;
    text-align: center;
    line-height: 24px;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: 3em;
    text-overflow: ellipsis;
}
.p-cantent {
    width: 100%;
    height: auto;
    margin: 40px auto;
    text-align: center;
}
.p-cantent ul {
    width: inherit;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 40px;
}
.p-cantent ul li {
    transition: all 300ms;
    position: relative;
    width: 48%;
    height: 200px;
    background: white;
    margin-bottom: 24px;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    cursor: pointer;
}
.p-cantent ul li:hover{
  transition: all 300ms;
  transform: translateY(0px) scale(1.02);
  box-shadow: 0 0 50px rgba(0,0,0,.1);
}
.p-cantent-l {
    width: 170px;
    height: 170px;
    margin-right: 15px;
    overflow: hidden;
    min-width: 170px;
}
.p-cantent-l img {
    width: inherit;
    height: inherit;
    transition: all 300ms;
    cursor: pointer;
}
.p-cantent-l img:hover{
  transition: all 300ms;
  transform: scale(1.1);
}
.recruitLi {
    width: 50%;
    height: 482px;
    margin: 0 20px;
    background: white;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    transition: all 300ms;
}
.p-cantent .recruitLi:nth-child(2){animation-delay: 300ms;}
.p-cantent .recruitLi:nth-child(3){animation-delay: 600ms;}
.recruitLi:hover{
  transition: all 300ms;
  transform: translateY(-10px);
  box-shadow: 0 0 40px rgba(0,0,0,.1);
}
.recruitMore::after{
  transition: all 300ms;
  content: '\e615';
  transform: rotate(-90deg) translateY(-40px);
  display: inline-table;
  font-size: 12px;
  vertical-align: text-bottom;
  margin-left: 10px;
}
.recruitLi:hover .recruitMore::after{
  transition: all 300ms;
  transform: rotate(-90deg) translateY(-35px);
}
.recruit {
    width: inherit;
    height: auto;
    margin: 20px auto 60px;
    display: flex;
    cursor: pointer;
}
.recruiIcon {
  width: 100%;
  height: auto;
  line-height: 116px;
  text-align: center;
  margin: 0 auto 10px;
}
.recruiIcon i {
    font-size: 146px;
    vertical-align: text-top;
    color: #00537A;
}
.recruitLi h4 {
    font-size: 20px;
    line-height: 60px;
}
.recruitLi h1 {
    font-size: 38px;
    line-height: 40px;
    font-family: "UDC 1.04-Bold";
    color: #00537A;
}
.recruitLi h1::after{
  content: 'K';
  color: #00537A;
  font-family: "SF Pro SC","SF Pro Text","SF Pro Icons","PingFang SC","Helvetica Neue","Helvetica","Arial",sans-serif;
}
.recruitLi h4 {
    font-size: 20px;
    line-height: 52px;
}
.recruitMore {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #da1800;
  display: table-column;
  bottom: 40px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  width: 160px;
}
.recruitMore a{
  color: white;
  padding: 10px 38px;
  line-height: 38px;
}
.recruitMore:hover{
  transition: all 300ms;
}
.recruitMore:hover::after{
  transition: all 300ms;
  transform: rotate(-90deg) translateY(-40px);
}
.Product-more {
  transition: all 300ms;
  position: absolute;
  bottom: 20px;
  right: 30px;
  font-size: 13px;
  background: rgb(218, 24, 0);
  width: 100px;
  height: 28px;
  line-height: 28px;
  color: white;
  text-align: center;
}
.Product-more::after{
  content: '\e615';
  color: white;
  transform: rotate(270deg);
  display: inline-block;
  font-size: 12px;
  vertical-align: bottom;
  margin-left: 5px;
  transform: rotate(-90deg) translateY(-40px);
}
.Product-more:hover{
  transition: all 300ms;
  transform: translateX(10px);
}
.toProduct {
    padding: 10px 20px;
    border: 1px solid #00537A;
    display: inline;
    border-radius: 20px;
    margin: 0 auto;
    color: #00537A;
    transition: all 300ms;
    cursor: pointer;
}
.toProduct a{
  color: #00537A;
}
.toProduct::after{
  content: '\e615';
  color: #00537A;
  transform: rotate(270deg);
  display: inline-block;
  font-size: 14px;
  vertical-align: inherit;
  margin-left: 6px;
}
.toProduct:hover{
  transition: all 300ms;
  background: #00537A;
  color: white;
}
.toProduct:hover a{
  color: white;
}
.toProduct:hover::after{
  transition: all 300ms;
  color: white;
  transform: translateX(5px) rotate(270deg);
}
</style>
