<template>
  <div>
    <div class="TopNavigation-box">
      <div class="TopNavigation">
        <h4>{{ TopNavigationInfo.welcome }}</h4>
        <div class="TopNavigation-phone"><i class="iconfont icon-phone"></i>{{ TopNavigationInfo.phone}}</div>
      </div>
    </div>
    <div class="top-nav">
      <div class="top-nav-content">
        <div class="top-nav-logo"><router-link to="/"><img src="../../src/images/hc-logo.svg" alt="泓创科技logo"></router-link></div>
        <h4>{{ TopNavigationInfo.describe }}</h4>
      </div>
        <div class="top-navbar">
          <ul>
            <li v-for="item in navbarData" v-bind:key="item.index">{{ item.navName }}<div class="onck" v-if="!item.subList" @click="pushToTargetPage(item)"></div><div class="navIcon" v-if="item.subList"><i class="iconfont icon-xialakuang-xiala"></i></div><div class="subList" v-if="item.subList">
              <div class="top-navbar-li" @click="pushToTargetPage(ite)" v-for="ite in item.subList" v-bind:key="ite.index">{{ ite.navName }}</div>
            </div>
            </li>
          </ul>
        </div>
    </div>
  </div>
</template>

<script>
// import AV from 'leancloud-storage'
import { WOW } from 'wowjs'
export default {
  name: 'TopNavigation',
  props: {
    TopNavigationInfo: Object
  },
  data: function () {
    return {
      navbarData: [
        {
          navName: '首页',
          path: '/'
        },
        {
          navName: '产品中心',
          path: '/ProductCenter',
          subList: [
            {
              navName: '产品列表',
              path: '/WisdomView'
            },
            {
              navName: '产品详情',
              path: '/QitaView'
            }
          ]
        },
        {
          navName: '关于我们',
          path: '/AboutView',
          subList: [
            {
              navName: '企业介绍',
              path: '/AboutView'
            },
            {
              navName: '企业文化',
              path: '/AboutView'
            }
          ]
        },
        {
          navName: '人才招聘',
          path: '/RecruitView'
        },
        {
          navName: '联系我们',
          path: '/'
        }
      ]
    }
  },
  mounted () {
    new WOW().init()
  },
  created () {
  },
  methods: {
    pushToTargetPage (e) {
      if (e.navName === '联系我们') {
        console.log(e.navName)
        this.$router.push(e.path + '?toPnum=3000')
        this.$emit('clickEvent')
      } else {
        this.$router.push({ path: e.path })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.onck {
    position: absolute;
    width: inherit;
    height: 50px;
    top: 0;
}
  .TopNavigation-box {
      width: 100%;
      height: 65px;
      background: #4A5154;
      line-height: 65px;
      color: white;
  }
  .TopNavigation {
      position: relative;
      width: 1280px;
      height: inherit;
      margin: 0 auto;
  }
  .TopNavigation-phone {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 18px;
      font-family: "UDC 1.04-Bold";
  }
  .TopNavigation-phone i {
      font-size: 28px;
      vertical-align: sub;
      margin-right: 5px;
  }
  .top-nav {
      position: relative;
      z-index: 3;
      width: 100%;
      height: 108px;
      background: white;
  }
  .top-nav-content {
      position: relative;
      width: 1280px;
      margin: 0 auto;
      height: inherit;
  }
  .top-nav-logo {
      position: absolute;
      width: 155px;
      height: 47px;
      top: 22px;
      left: 0;
      cursor: pointer;
  }
  .top-nav-logo a {
      width: inherit;
      height: inherit;
      display: block;
      cursor: pointer;
}
  .top-nav-content h4 {
      color: #00537A;
      text-align: right;
      line-height: 90px;
  }
  .top-navbar {
      width: 1026px;
      height: 50px;
      background: #00537A;
      transform: translateY(-50%);
      display: flex;
      padding: 0 10px;
      box-sizing: border-box;
  }
  .top-navbar ul {
      display: flex;
      width: inherit;
  }
  .top-navbar ul li {
      position: relative;
      line-height: 50px;
      color: white;
      list-style-type: none;
      width: 100%;
      text-align: center;
      cursor: pointer;
      transition: all 300ms;
  }
  .top-navbar ul li:hover{
    transition: all 300ms;
    background: #00699b;
  }
  .top-navbar ul li:hover .navIcon{
    transition: all 300ms;
    transform:rotate(180deg);
  }
  .top-navbar ul li:hover .subList{
    transition: all 300ms;
    transform: rotateX(0deg);
    opacity: 1;
  }
  .subList{
    transition: all 300ms;
    opacity: 0;
    color: black;
    transform: rotateX(90deg);
  }
  .bar-line{
    opacity: 0;
  }
  .top-navbar-li{
    transition: all 300ms;
    background-color: rgba(255,255,255,.8);
  }
  .top-navbar-li:hover{
    transition: all 300ms;
    color: white;
    background: #00699b;
  }
  .navIcon{
    position: absolute;
    right: 50px;
    top: 0;
    transition: all 300ms;
    transform: rotate(0deg);
  }
  .top-navbar ul li .top-navbar-li:last-child{
    border-radius: 0 0 8px 8px;
  }
  .navIcon i{
    font-size: 12px;
  }
</style>
