<template>
  <div class="Floating">
    <div class="FloatingBox">
      <ul>
        <li>
          <i class="iconfont icon-shejiao-weixin"></i>
          <div class="showInfo"><img :src="floatins.weixinEwm" alt="微信二维码"/></div>
          <div class="libg"></div>
        </li>
        <li>
          <i class="iconfont icon-erweima"></i>
          <div class="showInfo"><img :src="floatins.gonggonghaoEwn" alt="公众号二维码"/></div>
          <div class="libg"></div>
        </li>
        <li>
          <i class="iconfont icon-phone"></i>
          <div class="showInfo showIphone">{{floatins.phone}}</div>
          <div class="libg"></div>
        </li>
        <li :class="btnFlag?'showGoTop':''" @click="backtop">
          <i class="iconfont icon-top"></i>
          <div class="libg"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FloatingBox',
  props: ['floatins'],
  data: function () {
    return {
      scrollTop: 0, // 默认距离顶部的距离
      btnFlag: false
    }
  },
  components: {
    // BMapGL
  },
  created () {
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.scrollToTop)
  },
  mounted () {
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
    const that = this
    window.addEventListener('scroll', that.scrollToTop, true)
  },
  methods: {
    // 返回顶部
    backtop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop () {
      const that = this
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 500) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    }
  }
}
</script>
<style scoped>
.Floating {
  position: fixed;
  right: 20px;
  bottom: 200px;
  z-index: 9;
  color: white;
  width: 70px;
  height: auto;
  transition: all 300ms;
}
@media screen and (min-width: 2480px) {.Floating {right: 200px;}}
@media screen and (max-width: 2480px) and (min-width: 1921px){.Floating {right: 150px;}}
@media screen and (max-width: 1920px) and (min-width: 1601px){.Floating {right: 120px;}}
@media screen and (max-width: 1600px) and (min-width: 1331px){.Floating {right: 80px;}}
@media screen and (max-width: 1330px) and (min-width: 700px){.Floating {right: 50px;}}
.FloatingBox {
    width: inherit;
    height: auto;
    margin: 0 auto;
    box-shadow: 0 0 30px rgba(0,0,0,.1);
}
.FloatingBox ul {
    width: inherit;
    height: auto;
}
.FloatingBox ul li {
    position:relative;
    width: inherit;
    height: 70px;
    text-align: center;
    line-height: 70px;
    list-style-type: none;
    background: rgba(255,255,255,.98);
    cursor: pointer;
    border-bottom: 1px solid #f2f2f2;
    transition: all 300ms;
}
.FloatingBox ul li:hover{
  /* background: rgba(218, 24, 0, 1); */
  transition: all 300ms;
}
.showGoTop{
  height: 70px!important;
  opacity: 1!important;
  transform: rotateX(0) !important;
  transition: all 300ms;
}
.showIphone {
    padding: 0!important;
    height: 40px !important;
    line-height: 40px;
}
.showInfo{
  opacity: 0;
  width: 132px;
  height: 132px;
  background: white;
  position: absolute;
  left: -122px;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #333333;
  box-shadow: 0 0 30px rgba(0,0,0,.1);
  padding: 5px;
  box-sizing: border-box;
  transform: rotateY(90deg);
}
.showInfo img {
    width: 122px;
    height: 122px;
}
.showInfo::after{
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid white;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
.FloatingBox ul li:hover .showInfo{
  opacity: 1;
  left: -142px;
  transform: rotateY(0deg);
  transition: all 300ms;
}
.FloatingBox ul li:hover i{
  color: white;
  transition: all 300ms;
}
.FloatingBox ul li:hover .libg{
  opacity: 1;
  bottom: 0;
  transition: all 300ms;
}
.FloatingBox ul li i {
    position: relative;
    z-index: 1;
    color: #c8c8c8;
    font-size: 30px;
    transition: all 300ms;
}
.libg{position: absolute; width: inherit;height: inherit;background-color: rgba(218, 24, 0, 1);bottom: -70px;transition: all 300ms;z-index: 0;opacity: 0;}
.FloatingBox ul li:nth-child(2) i{font-size: 26px;}
.FloatingBox ul li:nth-child(3) i{font-size: 32px;}
.FloatingBox ul li:nth-child(4) i{font-size: 32px; border: none;}
.FloatingBox ul li:nth-child(4){border: none;  opacity: 0; transform: rotateX(90deg);height: 0;}
</style>
