import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BaiduMap from 'vue-baidu-map'
import VueCarousel from 'vue-carousel'
import VueParticles from 'vue-particles'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../src/global.css'
import '../src/iconfont.css'
// import 'animate.css'
import { WOW } from 'wowjs'
import 'wowjs/css/libs/animate.css'
// 引入Leancloud
import AV from 'leancloud-storage'
import 'swiper/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI)
Vue.use(VueCarousel)
Vue.use(VueParticles)
// Vue.use(animated)
// Vue.use(AnimateCSS)
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'Mz1raLunK32DHcAaKZ5ySvD6kad3xesA'
})
// Leancloud初始化
AV.init({
  appId: '9rXqIZHsGPUfozzLKmCRzDaU-gzGzoHsz',
  appKey: 'W4rV92VWVQq2GRbXEjf93tSb',
  serverURL: 'https://9rxqizhs.lc-cn-n1-shared.com'
})
Vue.prototype.$wow = WOW
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
