<template>
<div>
    <div class="TopNavigation-box">
        <div class="banner">
          <!-- <div class="banner-text">
              <h1 class="animate__animated animate__fadeInUp ">整体智能自动化系统解决方案</h1>
              <p class="animate__animated animate__fadeInUp  animate__delay-1s">我们是一家专业的智能自动化 解决方案服务商，为生产制造企业提供全方位自动化系统的规划设计、系统集成</p>
              <p class="animate__animated animate__fadeInUp  animate__delay-1s">软件开发、设备定制、现场安装调试和售后等一站式服务。</p>
          </div> -->
          <swiper :options="swiperOption" class="swiper-radius">
            <swiper-slide v-for="item in BannerList" v-bind:key="item.index">
              <div class="banner-text">
                <h1>{{ item.title }}</h1>
                <p v-for="ite in item.textList" :key="ite.index">{{ ite }}</p>
              </div>
              <!-- <img @click="openwindow()" class="bottom-banner-img" :src="item.imgUrl" :alt="item.text"> -->
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <div class="bannerMask"></div>
          <video width="100%" height="580px" autoplay loop muted :poster="imgUrl">
              <source src="../video/banner-jixiebi.mp4" type="video/mp4">
              <object data="../video/banner-jixiebi.mp4">
              <embed src="../video/banner-jixiebi.mp4">
              </object>
          </video>
      </div>
    </div>
</div>
</template>

<script>
// import AV from 'leancloud-storage'
import { swiper } from 'vue-awesome-swiper'
export default {
  name: 'TopBanner',
  props: {
    slidesPerView: {
      type: Number,
      default: 1
    },
    BannerList: {
      type: Array
    }
  },
  components: {
    swiper
  },
  data: function () {
    return {
      imgUrl: 'https://pic.imgdb.cn/item/6555f461c458853aefb95186.jpg',
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 3000
        },
        speed: 500
      }
    }
  },
  created () {
  },
  methods: {
    openwindow () {
      this.$emit('setting')
      console.log()
    },
    opensale () {
      this.$emit('onsale')
      console.log()
    }
  }
}
</script>
<style scoped>
.swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: 30px;
}
.swiper-container-horizontal > .swiper-pagination-bullets /deep/ span {
    width: 28px;
    height: 4px;
    border-radius: 0;
    background: rgba(255,255,255,.35);
}
.swiper-container-horizontal > .swiper-pagination-bullets /deep/ .swiper-pagination-bullet-active{
    background: rgb(218, 24, 0);
}
.swiper-container{
  z-index: 2;
}
.banner{position: relative;width: 100%;height: 680px;background: rgb(2, 3, 4);overflow: hidden;}
.banner video{width: 100%;height: auto;position: absolute;top: 0;}
.bannerMask{position: absolute;top: 0;width: 100%;height: inherit;background: rgba(0,0,0,.8);z-index: 1;}
.banner-text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
    color: white;
    width: 1000px;
    height: 200px;
    transform: scale(1.2);
}
.banner-text h1 {
    font-family: 'TencentSansW7CN';
    font-size: 62px;
    text-align: center;
    line-height: 100px;
    font-weight: bold;
    margin-bottom: 20px;
}
.banner-text p {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
}
.swiper-slide{
  width: 100%;
  height: 680px;
}
.swiper-slide img{
  width: 100%;
  height: auto;
}
.banner-text{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 1200px;
  height: 200px;
}
.swiper-slide h1{
  font-size: 52px;
  color: white;
}
</style>
