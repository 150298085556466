<template>
  <div class="bot-navbar">
    <div class="BottomNavigation">
      <div class="b-navbar wow animate__animated animate__fadeInUp">
        <div class="b-navbar-l">
          <div class="b-navbar-ewm">
            <img :src="WebInf.gonggonghaoEwn" alt="二维码"/>
            <p>扫一扫 关注我们</p>
          </div>
        </div>
        <div class="b-navbar-c">
          <div class="b-navbar-content">
            <h3>联系我们</h3>
            <p>联系人：{{ WebInf.contactsName }}</p>
            <p>电子邮箱：{{ WebInf.mail }}</p>
            <p>联系电话：{{ WebInf.phone }}</p>
            <p>公司地址：{{ WebInf.address }}</p>
          </div>
        </div>
        <div class="b-navbar-r">
          <div class="b-navbar-content">
            <h3>产品中心</h3>
            <p><router-link to="/WisdomView">产品中心</router-link></p>
            <p><router-link to="/WisdomView">应用案例</router-link></p>
          </div>
          <div class="b-navbar-content">
            <h3>关于我们</h3>
            <p><router-link to="/AboutView">企业介绍</router-link></p>
            <p><router-link to="/AboutView">企业文化</router-link></p>
            <p><router-link to="/AboutView">合作案例</router-link></p>
          </div>
          <div class="b-navbar-content">
            <h3>人才招聘</h3>
            <p><router-link to="/WuhanView">招聘岗位</router-link></p>
          </div>
          <div class="b-navbar-content">
            <h3>友情链接</h3>
            <!-- <p><a href="https://www.sanygroup.com/" target="_blank">三一重工</a></p> -->
            <p v-for="(item, index) in WebInf.linkData" :key="index"><a :href="item.href" target="_blank">{{item.linkName}}</a></p>
          </div>
        </div>
      </div>
      <p><span v-html="WebInf.filings"></span> Copyright ©2023 泓创科技 版权所有 违者必究 <span class="item" @click="tipInfo">网站纠错</span><span @click="toLogin"> 管理登录</span><span class="showQidi"> {{WebInf.slogan}}<div class="Qidixcx"><img :src="WebInf.qidixcx" alt="启迪创意设计中心"/></div></span></p>
      <div class="b-navbar-bg"></div>
    </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
  name: 'BottomNavigation',
  props: ['WebInf'],
  data: function () {
    return {
    }
  },
  components: {
  },
  created () {
  },
  mounted () {
    new WOW().init()
  },
  methods: {
    tipInfo () {
      // console.log('网站纠错与技术支持请联系' + this.WebInf.AuthorPhone)
      this.$alert('<span>网站纠错与技术支持请联系：' + this.WebInf.AuthorPhone + '</span><span style="color: teal;font-style:italic;"> VNode</span>', '消息', {
        dangerouslyUseHTMLString: true,
        showClose: false,
        confirmButtonText: '朕知道了'
      })
    },
    toLogin () {
      this.$router.replace('/LoginView')
    }
  }
}
</script>
<style scoped>
.showQidi{
  position: relative;
}
.Qidixcx{
  position: absolute;
  top: -125px;
  left: 0;
  right: 0;
  margin: auto;
  width: 120px;
  height: auto;
  text-align: center;
  background: white;
  border-radius: 60px 60px 15px 15px;
  overflow: hidden;
  padding: 10px 0 5px 0;
  box-sizing: border-box;
  opacity: 0;
}
.Qidixcx img{
  width: 90%;
  margin: 0 auto;
}
.showQidi:hover .Qidixcx {
    transition: all 300ms;
    top: -135px;
    opacity: 1;
}
.bot-navbar {
    width: 100%;
    height: 400px;
    margin: 0px auto 0;
    background: rgba(4, 20, 33, 1);
    color: white;
}
.b-navbar-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
    background: url(../images/bg-img@2x.png);
    background-size: 100% auto;
    background-size: 575px auto;
    background-repeat: no-repeat;
    background-position: 45% 20%;
    animation: navbarAni 4s linear infinite;
    z-index: 0;
}
.BottomNavigation {
    position: relative;
    width: 1280px;
    height: inherit;
    margin: 0 auto;
}
.b-navbar {
    width: 100%;
    height: 350px;
    padding: 80px 0 30px;
    box-sizing: border-box;
    display: flex;
    z-index: 2;
    position: relative;
    transition: all 300ms;
}
.b-navbar-ewm img {
    width: inherit;
}
.b-navbar-ewm p {
  text-align: center;
  line-height: 36px;
}
@keyframes navbarAni {
  0%{opacity: 0.05;}
  50%{opacity: 0.2;}
  100%{opacity: 0.05;}
}

.b-navbar + p span a,
.b-navbar + p {
    position: relative;
    z-index: 2;
    text-align: center;
    font-size: 13px;
    color: rgb(255,255,255,.45)!important;
    margin: 10px auto 0;
}
.b-navbar + p span a,
.b-navbar + p span{
  cursor: pointer;
  font-size: 13px;
  transition: all 300ms;
}
.b-navbar + p span:hover a,
.b-navbar + p span:hover{
  color: rgb(255,255,255,1);
  transition: all 300ms;
}
.b-navbar-c {
    width: 420px;
    height: 320px;
    margin: 0 30px 0 220px;
}
.b-navbar-l {
    width: 132px;
    height: 320px;
    margin: 0;
    text-align: center;
}
.b-navbar-content {
    width: 100%;
    height: auto;
}
.b-navbar h3 {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    line-height: 40px;
    margin-bottom: 10px;
}
.b-navbar p,
.b-navbar p a{
    line-height: 28px;
    color: rgba(255,255,255,.5);
    font-size: 13px;
    cursor: pointer;
    white-space: nowrap;
    transition: all 300ms;
}
.b-navbar p:hover,
.b-navbar p:hover a{
  color: white;
  transition: all 300ms;
}
.b-navbar-r {
    width: 100%;
    height: 320px;
    display: flex;
    margin-left: 100px;
}
</style>
