<template>
  <div class="home" ref="viewport">
    <TopNavigation :TopNavigationInfo="TopNavigationInfos" @clickEvent="backtop"></TopNavigation>
    <TopBanner :BannerList="BannerLists"></TopBanner>
    <ProductCente :ProductData="ProductDatas" :ChanPinText="ChanPinTextS"></ProductCente>
    <AboutUs :AboutData="AboutUsData"></AboutUs>
    <RecruitmentTection :RecruitData="RecruitDatas" :rencaizhaopText="rencaizhaopTexts"></RecruitmentTection>
    <ContactUs :Contact="Contacts" :lianxiwomen="lianxiwomens"></ContactUs>
    <BottomNavigation :WebInf="WebInfo"></BottomNavigation>
    <FloatingBox :floatins="floatinss"></FloatingBox>
  </div>
</template>

<script>
import AV from 'leancloud-storage'
// @ is an alias to /src
import TopNavigation from '@/components/TopNavigation.vue'
import TopBanner from '@/components/TopBanner.vue'
import ProductCente from '@/components/ProductCente.vue'
import AboutUs from '@/components/AboutUs.vue'
import RecruitmentTection from '@/components/RecruitmentTection.vue'
import ContactUs from '@/components/ContactUs.vue'
import BottomNavigation from '@/components/BottomNavigation.vue'
import FloatingBox from '@/components/FloatingBox.vue'
// import { config } from 'process'

export default {
  name: 'HomeView',
  components: {
    TopNavigation,
    TopBanner,
    ProductCente,
    AboutUs,
    RecruitmentTection,
    ContactUs,
    BottomNavigation,
    FloatingBox
  },
  data: function () {
    return {
      videoUrl: '../video/banner-jixiebi.mp4',
      toPnum: 0,
      WebInfo: {},
      Contacts: {},
      floatinss: {},
      AboutUsData: {},
      ProductDatas: [],
      RecruitDatas: [],
      BannerLists: [],
      TopNavigationInfos: {},
      ChanPinTextS: '',
      rencaizhaopTexts: '',
      lianxiwomens: ''
    }
  },
  created () {
    this.getAppData()
    this.toPnum = this.$route.query.toPnum // 121345  params传参方式接收
  },
  mounted () {
    const that = this
    window.addEventListener('scroll', that.scrollToTop, true)
    // this.backtop()
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.scrollToTop)
  },
  methods: {
    // 返回顶部
    backtop () {
      this.toPnum = this.$route.query.toPnum // 121345  params传参方式接收
      const _this = this
      const timeTop = setInterval(() => {
        document.documentElement.scrollTop += 20
        document.body.scrollTop += 20
        if (document.documentElement.scrollTop >= _this.toPnum || document.body.scrollTop >= _this.toPnum) { clearInterval(timeTop) }
        if (document.documentElement.scrollTop >= 3000 || document.body.scrollTop >= 3000) { clearInterval(timeTop) }
      }, 1)
    },
    getAppData () {
      const query = new AV.Query('Hongchuang')
      query.get('655ddb499a790104bdf2125a').then((Hongchuang) => {
        this.WebInfo = Hongchuang.get('WebInfo') // 获取底部数据
        this.Contacts = Hongchuang.get('WebInfo') // 获取联系我们数据
        this.floatinss = Hongchuang.get('WebInfo') // 获取浮动按钮数据
        this.AboutUsData = Hongchuang.get('aboutInfo') // 获取关于我们数据
        this.ProductDatas = Hongchuang.get('ProductData') // 获取产品中心数据
        this.RecruitDatas = Hongchuang.get('RecruitData') // 获取人才招聘数据
        this.BannerLists = Hongchuang.get('BannerList') // 获取Banner数据
        this.TopNavigationInfos = Hongchuang.get('WebInfo') // 获取顶部网站内容
        this.ChanPinTextS = Hongchuang.get('WebInfo').ChanPinText // 获取产品中心副标题
        this.rencaizhaopTexts = Hongchuang.get('WebInfo').rencaizhaopText // 获取人才招聘副标题
        this.lianxiwomens = Hongchuang.get('WebInfo').lianxiwomen // 获取联系我们副标题
      })
    }
  }
}
</script>
<style scoped>
</style>
