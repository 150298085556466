<template>
  <div class="ContactUs" id="ContactUs">
    <div class="ProductCenter">
      <h2 class="wow animate__animated animate__fadeInUp">{{ Contact.ProductCenterTitle }}</h2>
      <p class="wow animate__animated animate__fadeInUp">{{ lianxiwomen }}</p>
      <div class="p-cantent">
        <div class="recruit">
          <div id="allmap" class="wow animate__animated animate__fadeInUp">
            <baidu-map class="map" :center="Contact.city" :zoom="mapData.zoom" scroll-wheel-zoom>
              <bm-info-window :position="Contact.city" :title="mapData.title" :show="infoWindow.show">
                <p v-html="mapData.contents"></p>
                <!-- <button @click="clear">Clear</button> -->
              </bm-info-window>
            </baidu-map>
          </div>
        </div>
        <!-- <div class="toProduct iconfont">查看更多产品</div> -->
      </div>
    </div>

  </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
  name: 'ContactUs',
  props: ['Contact', 'lianxiwomen'],
  data: function () {
    return {
      ProductCenterTitle: '联系我们',
      mapData: {
        title: '武汉泓创科技有限公司',
        contents: '<p style="text-align: left;">联系人: 邓经理</p><p style="text-align: left;">电子邮箱: dengjl@hongchuang.com</p><p style="text-align: left;">联系电话: 027-89898989</p><p style="text-align: left;white-space: nowrap;">公司地址: 武汉市经济技术开发区南太子湖创新谷启迪协信科创园</p>',
        city: { lng: 114.06616, lat: 30.451346 },
        zoom: 15
      },
      infoWindow: {
        show: true,
        contents: '联系人: 邓经理,电子邮箱: dengjl@hongchuang.com,联系电话: 027-86869999,公司地址: 武汉市经济技术开发区南太子湖创新谷启迪协信科创园'
      }
    }
  },
  mounted () {
    new WOW().init()
  },
  beforeUpdate () {
    this.mapData = {
      title: this.Contact.title,
      contents: '<p style="text-align: left;">联系人: ' + this.Contact.contactsName + '</p><p style="text-align: left;">电子邮箱: ' + this.Contact.mail + '</p><p style="text-align: left;">联系电话: ' + this.Contact.phone + '</p><p style="text-align: left;white-space: nowrap;">公司地址: ' + this.Contact.address + '</p>',
      // city: { lng: 114.06616, lat: 30.451346 },
      city: this.Contact.city,
      zoom: 15
    }
    this.infoWindow = {
      show: true,
      contents: '联系人: ' + this.Contact.contactsName + ',电子邮箱: ' + this.Contact.mail + ',联系电话: ' + this.Contact.phone + ',公司地址: ' + this.Contact.address
    }
  },
  created () {
  },
  methods: {
    infoWindowClose (e) {
      this.infoWindow.show = false
    },
    infoWindowOpen (e) {
      this.infoWindow.show = true
    },
    clear () {
      this.infoWindow.contents = ''
    }
  }
}
</script>
<style scoped>
.map{width: 800px;height: 400px;background: rgba(0,0,0,.2);margin: 0 auto;}
.ContactUs {
    background: white;
    min-height: 500px;
    padding: 20px 0 80px;
    box-sizing: border-box;
}
.ProductCenter {
    width: 1280px;
    height: auto;
    margin: 50px auto;
}
.ProductCenter h2 {
    position: relative;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    line-height: 60px;
    margin-bottom: 10px;
}
.ProductCenter h2::before{
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: '';
  width: 50px;
  height: 4px;
  background: #DA1800;
}
.ProductCenter p {
    font-size: 14px;
    text-align: center;
    line-height: 24px;
}
.p-cantent {
    width: 100%;
    height: auto;
    margin: 40px auto;
    text-align: center;
}
.p-cantent ul {
    width: inherit;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 40px;
}
.p-cantent ul li {
    transition: all 300ms;
    position: relative;
    width: 48%;
    height: 200px;
    background: white;
    margin-bottom: 24px;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    cursor: pointer;
}
.p-cantent ul li:hover{
  transition: all 300ms;
  transform: translateY(0px) scale(1.02);
  box-shadow: 0 0 50px rgba(0,0,0,.1);
}
.p-cantent-l {
    width: 170px;
    height: 170px;
    margin-right: 15px;
    overflow: hidden;
    min-width: 170px;
}
.p-cantent-l img {
    width: inherit;
    height: inherit;
    transition: all 300ms;
    cursor: pointer;
}
.p-cantent-l img:hover{
  transition: all 300ms;
  transform: scale(1.1);
}

.recruitMore::after{
  transition: all 300ms;
  content: '\e615';
  transform: rotate(-90deg) translate(1px,0px);
  display: inline-table;
  font-size: 12px;
  vertical-align: text-bottom;
  margin-left: 10px;
}
.recruit {
    width: inherit;
    height: auto;
    margin: 20px auto 60px;
    display: flex;
    cursor: pointer;
}
.recruitMore {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    background: #DA1800;
    display: table;
    bottom: 40px;
    padding: 8px 50px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.recruitMore:hover{
  transition: all 300ms;
}
.recruitMore:hover::after{
  transition: all 300ms;
  transform: rotate(-90deg) translate(1px,10px);
}
.Product-more {
  transition: all 300ms;
  position: absolute;
  bottom: 20px;
  right: 30px;
  font-size: 13px;
  background: rgb(218, 24, 0);
  width: 100px;
  height: 28px;
  line-height: 28px;
  color: white;
  text-align: center;
}
.Product-more::after{
  content: '\e615';
  color: white;
  transform: rotate(270deg);
  display: inline-block;
  font-size: 12px;
  vertical-align: bottom;
  margin-left: 5px;
}
.Product-more:hover{
  transition: all 300ms;
  transform: translateX(10px);
}
.toProduct {
    padding: 10px 20px;
    border: 1px solid #00537A;
    display: inline;
    border-radius: 20px;
    margin: 0 auto;
    color: #00537A;
    transition: all 300ms;
    cursor: pointer;
}
.toProduct::after{
  content: '\e615';
  color: #00537A;
  transform: rotate(270deg);
  display: inline-block;
  font-size: 14px;
  vertical-align: inherit;
  margin-left: 6px;
}
.toProduct:hover{
  transition: all 300ms;
  background: #00537A;
  color: white;
}
.toProduct:hover::after{
  transition: all 300ms;
  color: white;
  transform: translateX(5px) rotate(270deg);
}
.map /deep/ .anchorBL{display: none;}
.map /deep/ .BMap_cpyCtrl {
    display: none;
}
</style>
